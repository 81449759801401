@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;700&family=PT+Sans&display=swap');
div, p, a, li{ font-family: 'Josefin Sans', sans-serif; font-size: 1.15rem; font-weight: 400;}
h1,h2,h3,h4{font-family: 'PT Sans', sans-serif;font-size: 1.75rem;}
h1{ font-size: 3rem;}
.animate-bottom{position:relative;animation:animatebottom 0.8s}@keyframes animatebottom{from{bottom:-40px;opacity:0} to{bottom:0;opacity:1};}
.bg-image-home{background-size:cover; background-position: top center; background-repeat: no-repeat; background-image: url('../public/01.jpg');}
.bg-image-01{background-size:cover; background-position: top center; background-repeat: no-repeat; background-image: url('../public/02.jpg');}
.bg-gradient-danube{background:linear-gradient(90deg,rgba(87, 136, 189, 0.75) 5%,rgba(0,0,0,0) 100%); color: white;}
.bg-gradient-sunglow{background-image: linear-gradient(90deg, rgba(254, 204, 56, 0.75) 25%, rgba(0, 0, 0, 0) 100% ); color: #444444;}

.bg-danube{background-color: #5788BD; color: white;}
.bg-mantis{background-color: #84BE61; color: white;}
.bg-charcoal{background-color: #444444; color: white;}
.bg-sunglow{background-color: #FECC38!important; color: #444444!important;}
.bg-white{color: #444444;}

.navbar-toggler{border: 0;}
.navbar-toggler:focus{box-shadow: none;}
.navbar-toggler-icon { background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(87, 136, 189, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");}

.w-90{width: 90%;}
.mw-90{max-width: 98%;}
.border-white{border: 1rem solid white;}
.menu-links a{ color: #444444; text-decoration: none; font-size: .9rem ; margin: .35em;font-family: 'PT Sans', sans-serif;}
.small, .small a{font-size: .75rem;}

@media only screen and (min-width: 576px) {
div, p, a, li{ font-size: 1.5rem; }
}

@media only screen and (min-width: 992px) {
.mw-90{max-width: 90%;}
h1{font-size: 5rem;}
}

